<template>
    <div class="page-container">
        <page-title :title="'系统模板库'">活动配置的背景图片、背景视频、背景音乐的模板库管理</page-title>
        <div class="page-content">
            <div class="temp-box" v-for="(item,index) in templibList" :key="index">
                <div style="padding:5px;">
                    <div class="box-img">
                        <el-image :src="'/api'+item.tempThumbnail"
                        style="width:100%;height:100%;" :fit="fit"></el-image>
                        <div class="box-img-tips">
                            <span style="color:#ffffff;position: absolute;top: -46px;left: -40px;transform: rotate(45deg);">{{item.tempType}}</span>
                        </div>
                    </div>
                    <div class="box-info">
                        <span style="font-weight:bold;">{{item.tempName}}</span>
                        <div style="margin:6px 0px;">
                            <template v-if="item.tempTag!=null">
                                <el-tag type="success" size="mini" v-for="(tag,i) in item.tempTag.split(',')"
                                 :key="i" style="margin-right:5px;">{{tag}}</el-tag>
                            </template>
                            <template v-else>
                                <el-tag type="info" size="mini">默认</el-tag>
                            </template>
                        </div>
                        <span style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{item.tempDesc?item.tempDesc:'暂无'}}</span>
                    </div>
                    <div class="box-btn">
                        <div>
                            <span style="color:#999999;">使用量：</span><span>{{item.count}}</span>
                        </div>
                        <div>
                            <el-button type="text" icon="el-icon-edit" @click="editTemp(item)" style="padding:0px;">编辑</el-button>
                            <el-button type="text" icon="el-icon-delete" @click="deleteTemp(item.id)" style="padding:0px;color:#ff0000;">删除</el-button> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="temp-box">
                <div class="add" @click="addDialog = true">
                    <i class="el-icon-plus"></i>
                </div>
            </div>
        </div>
        <el-dialog title="新增模板" :visible.sync="addDialog"
         custom-class="my-dialog-style" @close="dialogClose">
            <el-form ref="form" :model="form" label-width="120px" size="mini">
                <el-form-item prop="id" style="display:none;"><el-input v-model="form.id" type="hidden"></el-input></el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="模板名称：" prop="tempName">
                            <el-input v-model="form.tempName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="模板分类：" prop="tempClass">
                            <el-input v-model="form.tempClass"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="资源类型：" prop="tempType">
                    <el-radio-group v-model="form.tempType" size="small" @change="typeChange">
                        <el-radio label="image">图片</el-radio>
                        <el-radio label="video">视频</el-radio>
                        <el-radio label="music">音乐</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="资源地址：" prop="tempUrl">
                    <el-row>
                        <el-col :span="18">
                            <el-input v-model="form.tempUrl" @blur="urlBlur"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <el-upload
                                class="upload-demo"
                                :show-file-list="false"
                                action="/api/upload/templib"
                                :data="{type:form.tempType}"
                                :on-success="handleSuccess"
                                :on-exceed="handleExceed"
                                :accept="form.tempType==='image'?'image/*':'video/*'">
                                <el-button slot="trigger" type="text">上传资源</el-button>
                            </el-upload>
                        </el-col>
                    </el-row>
                    <span style="font-size:12px;color: #989898;">输入地址则根据地址自动读取</span>
                </el-form-item>
                <el-form-item label="效果预览：" prop="tempThumbnail">
                    <div class="img-upload-show">
                        <div class="img-show">
                            <el-image style="width:100%;height:100%;"
                                :src="'/api'+form.tempThumbnail" :fit="'cover'">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>
                    </div>
                </el-form-item>
                <el-row>
                    <el-col :span="16">
                        <el-form-item label="标签：" prop="tempTag">
                            <el-input v-model="form.tempTag"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="使用量预设：" prop="count">
                            <el-input v-model="form.count"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="模板说明：" prop="tempDesc">
                    <el-input v-model="form.tempDesc"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false" size="small">取消新增</el-button>
                <el-button type="primary" @click="addNewTemp" size="small">确认新增</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:"TempLib",
    data(){
        return{
            form:{
                id:null,
                tempName:"",
                tempType:"image",
                tempClass:"",
                tempUrl:"",
                tempThumbnail:"",
                tempDesc:"",
                tempTag:"",
                count:10,
            },
            templibList:[],
            addDialog:false,
            fit:"cover"
        }
    },
    created(){

    },
    mounted(){
        this.initPage();
    },
    methods:{
        initPage(){
            const that = this;
            that.request.get("/templib/getByType",{}).then(res=>{
                console.log(res);
                that.templibList = res.data;
            });
        },
        addNewTemp(){
            const that = this;
            that.request.post("/templib/save",that.form).then(res=>{
                if(res.code === 200){
                    that.addDialog = false;
                    that.initPage();
                }
            });
        },
        editTemp(obj){
            const that = this;
            that.addDialog = true;
            that.$nextTick(()=>{
                that.form = JSON.parse(JSON.stringify(obj));
            });
        },
        deleteTemp(id){
            const that = this;
            that.$confirm('此操作将永久删除该模板内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("templib/delete",{id:id}).then(res=>{
                    if(res.code===200){
                        that.initPage();
                    }
                });
            }).catch({});
        },
        dialogClose(){
            this.$refs['form'].resetFields();
        },
        /**资源类型选择切换事件 */
        typeChange(){
            this.form.tempUrl="";
            this.form.tempThumbnail="";
        },
        urlBlur(){

        },
        handleSuccess(res){
            console.log(res);
            this.form.tempUrl = res.data.url;
            this.form.tempThumbnail = res.data.thumbUrl;
        },
        handleExceed(){

        }
    }
}
</script>
<style lang="less" scoped>
.page-container{
    .page-content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .temp-box{
            width: 300px;
            height: 270px;
            border: 1px solid #e3e3e3;
            font-size: 14px;
            margin-right: 10px;
            margin-bottom: 10px;
            .box-img{
                position: relative;
                width: 100%;
                height: 166px;
                .box-img-tips{
                    position: absolute;
                    width: 0px;
                    height: 0;
                    top: 0px;
                    right: 0px;
                    border-top: 60px solid #f31f1f;
                    border-left: 60px solid transparent;
                }
            }
            .box-info{
                display: flex;
                flex-direction: column;
            }
            .box-btn{
                padding: 6px 0px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            .add{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 80px;
                color: #e3e3e3;
            }
        }
    }
    .img-upload-show{
        .img-show{
            width: 240px;
            height: 135px;
            /deep/.image-slot{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background: #f5f7fa;
                color: #909399;
                font-size: 30px;
            }
        }
        .img-upload{
            .upload-demo{
                /deep/ .el-button{
                    padding: 5px 20px;
                }
                .el-upload__tip{
                    margin-top: 0px;
                }
            }
        }
    }
}
</style>